import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className={style.error}>
      <div className="op__block">
        <div className={style.error__container}>
          <div className="container-fluid">
            <h1 data-aos="fade-left" data-aos-delay="200">
              Página não encontrada
            </h1>
            <p data-aos="fade-right" data-aos-delay="400">
              Quem sabe ainda estamos escrevendo essa página ;)
            </p>
            <a
              href="/"
              className="op__btn"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
